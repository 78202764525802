var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{style:({ padding: '50px' })},[_c('v-row',{staticClass:"text-center pb-0 text-h6 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('jumbledWordGameInstruction'))+" ")]),_c('v-row',{staticClass:"text-center pb-0 text-h4 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('Score'))+": "+_vm._s(_vm.getScore())+" ")]),_c('v-row',{staticClass:"text-center pb-0 text-h6 font-weight-bold"},[_c('v-col',{class:_vm.levelSelected(1)},[_c('a',{on:{"click":function($event){return _vm.selectLevel(1)}}},[_vm._v(_vm._s(_vm.$t('Level'))+" 1")])]),_c('v-col',{class:_vm.levelSelected(2)},[_c('a',{on:{"click":function($event){return _vm.selectLevel(2)}}},[_vm._v(_vm._s(_vm.$t('Level'))+" 2")])]),_c('v-col',{class:_vm.levelSelected(3)},[_c('a',{on:{"click":function($event){return _vm.selectLevel(3)}}},[_vm._v(_vm._s(_vm.$t('Level'))+" 3")])])],1),_vm._l((_vm.seperatedWordList),function(w,i){return _c('v-row',{key:w.actualWord + i,staticClass:"text-center ml-1 mr-1",style:([
      w.shuffledWord.join('') === w.actualWord
        ? { border: '1px solid black' }
        : { border: 'none' }
    ])},[_c('draggable',{staticClass:"row wrap justify-space-around ma-0 pa-0",attrs:{"list":w.shuffledWord,"disabled":!_vm.enabled,"ghost-class":"ghost","move":_vm.checkMove},on:{"start":function($event){_vm.dragging = true},"end":function($event){return _vm.endDragging()}}},_vm._l((w.shuffledWord),function(c,j){return _c('v-col',{key:j,staticClass:"letter row-v",style:([
          w.shuffledWord.join('') === w.actualWord
            ? { background: 'green' }
            : { background: 'var(--v-primary-base)' }
        ])},[_vm._v(" "+_vm._s(c)+" ")])}),1),(w.shuffledWord.join('') === w.actualWord)?_c('v-col',{staticClass:"ma-0 pa-0",style:({ fontSize: '15px' }),attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$store.state.selectedLanguage === 'eng' ? w.english : w.hindi)+" ")]):_vm._e()],1)}),_c('GoBackButton')],2)
}
var staticRenderFns = []

export { render, staticRenderFns }